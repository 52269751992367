/*ChatInput.module.css*/
.textareaFormControl {
  resize: vertical;
  white-space: pre-wrap;
  min-height: 40px;
  max-height: 150px;
}

.formBackground {
  position: relative;
  background-color: var(--primary-light);
  border-radius: 5px;
  padding: 10px;
}

