.btn-primary {
  background-color: var(--btn-primary-bg);
  border-color: var(--btn-primary-hover-bg);
}

.btn-primary:hover {
  background-color: var(--btn-primary-hover-bg);
  border-color: var(--btn-primary-hover-bg);
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5);
}

.nav-link.active {
  color: var(--primary);
}

.nav-link:hover {
  color: var(--primary-dark);
}

.icon {
  cursor: pointer;
  margin-left: 8px;
}

.icon:hover {
  color: var(--secondary);
}

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
}
