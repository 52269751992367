/* ChatArea.module.css */
.chatContainer {
  display: flex;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background);
  height: calc(100vh - 145px); 
  overflow: auto;
  padding: 1rem;
  margin-top: 20px;
  margin-left: auto; /* 追加 */
  margin-right: auto; /* 追加 */
  background-image: url('/public/DALLE.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('/public/DALLE.png');
  background-size: cover;
}

.chatMessagesArea {
  flex-grow: 1; 
  overflow-y: auto;
  padding: 1rem;
  padding-right: 1.5rem;
  margin-bottom: 0.25rem;
  border: 2px solid #dee2e6;
  border-radius: 0.25rem;
}


.chatInputArea {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

