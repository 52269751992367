/* src/colors.css */
:root {
    --primary: #303F9F;
    --primary-dark: #3F51B5;
    --primary-light: #D6EAF8;

    --secondary: #009688;

    --background: #f5f5f5;
    --text-primary: #212121;
    --text-secondary: #757575;

    --btn-primary-bg: var(--primary);
    --btn-primary-hover-bg: var(--primary-dark);

}