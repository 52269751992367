/* Header.module.css */
.header {
  background-color: var(--primary); /* Set background color */
}

.header .customHeaderTitle {
  text-align: left; /* Add text-align: left */
  color: var(--background); /* Set text color */
  margin-left: 110px; /* Add left margin */
}

.header .customHeaderTitle:hover {
  color: var(--background);
}

.header .menuButton {
  border: none;
  padding: 0.5rem 1rem;
  color: var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .menuButton:hover {
  color: var(--hover); /* Change the color on hover */
  transition: color 0.3s ease-in-out;
}
