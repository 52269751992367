.sidebar {
  height: 100vh;
  overflow-y: auto;
  background-color: var(--background);
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
}

/* For tablets & desktops: adjust width */
@media (min-width: 768px) { /* adjust as needed */
  .sidebar {
    width: 280px;
  }
}

.sectionTitle {
  font-size: 1.25rem;
  font-weight: 500;
  padding: 1rem 0;
  color: var(--text-primary);
}

.topicItem {
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.navLink {
  color: var(--text-primary);
  text-decoration: none;
  display: block;
}

.navLinkActive {
  color: var(--primary);
  background-color: var(--primary-light);
}

.icon {
  margin-left: 0.5rem;
  color: var(--text-secondary);
}

.iconHover {
  color: var(--text-primary);
}

.divider {
  height: 1px;
  background-color: var(--primary-light);
  margin: 1rem 0;
}

.createButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--btn-primary-bg);
  color: white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.createButton:hover {
  background-color: var(--btn-primary-hover-bg);
}

.createButtonIcon {
  margin-right: 5px;
}
